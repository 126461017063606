<script>
import { useDashboardSwitcher } from './useDashboardSwitcher'

export default {
  name: 'NewDashboardToggle',
  data() {
    const dashboardSwitcher = useDashboardSwitcher()
    return {
      dashboardSwitcher,
      isEnabled: dashboardSwitcher.getUseNewDashboard(),
    }
  },
  computed: {
    canAccessNewDashboard() {
      let isAdmin = this.$store.getters.isAdmin;
      let restaurantHasFeatureFlag = this.$store.getters.currentRestaurant?.feature_flags?.some(flagObj => flagObj.slug === 'new_dashboard' && flagObj.enabled === true);
      let userIsNotEmployee = this.$store.getters.currentRole !== 'employee'; // Employees should not see the new dashboard yet, even if feature flag is enabled for that location
      return (isAdmin || restaurantHasFeatureFlag) && userIsNotEmployee;
    }
  },
  watch: {
    isEnabled(newValue) {
      this.dashboardSwitcher.setUseNewDashboard(newValue)
      if (newValue) {
        this.dashboardSwitcher.redirectToNewDashboard()
      }
    }
  }
}
</script>

<template>
  <div v-if="canAccessNewDashboard" class="toggle-container">
    <div class="toggle-content">
      <div class="toggle-header">
        <span class="toggle-title">New Dashboard</span>
        <div class="toggle-badge">Beta</div>
      </div>
      <p class="toggle-description">Try our redesigned dashboard</p>
    </div>
    <button 
      class="toggle-switch"
      role="switch"
      :aria-checked="isEnabled"
      @click="isEnabled = !isEnabled"
    >
      <span class="toggle-thumb" :class="{ 'toggle-thumb-checked': isEnabled }"></span>
    </button>
  </div>
</template>

<style scoped>
.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: 1px solid #0c0a09;
  border-radius: 0.5rem;
  position: relative;
  background: white;
  margin: 0.5rem;
  margin-bottom: 0;
  width: 100%;
}

.toggle-content {
  flex-grow: 1;
  /* margin-right: 2rem; */
}

.toggle-header {
  display: flex;
  align-items: center;
  gap: .25rem;
}

.toggle-title {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: -0.025em;
  color: #111827;
}

.toggle-badge {
  display: inline-flex;
  align-items: center;
  padding: 0 0.25rem;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: -0.025em;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
  color: #374151;
  background: white;
}

.toggle-description {
  margin: 0;
  font-weight: 500;
  font-size: 11px;
  color: #6b7280;
  letter-spacing: -0.025em;
}

.toggle-switch {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  width: 20px;
  height: 12px;
  background-color: #e5e7eb;
  border-radius: 999px;
  padding: 0;
  border: 2px solid transparent;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toggle-switch:focus {
  outline: 1px solid #111827;
  outline-offset: 1px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);
}

.toggle-switch[aria-checked="true"] {
  background-color: #111827;
}

.toggle-thumb {
  display: block;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: transform 0.2s;
  transform: translateX(0px);
}

.toggle-thumb-checked {
  transform: translateX(8px);
}
</style>