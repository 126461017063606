import Cookies from 'js-cookie'
import store from '@/store'

// Route mappings for paths that differ between dashboards
const routeMappings = {
  '/menu/menus': '/menu/editor',
  // Add more mappings as needed
}

export const useDashboardSwitcher = () => {
  const cookieDomain = '.orderli.com'
  const getUseNewDashboard = () => {
    const cookieValue = Cookies.get('useNewDashboard')
    return cookieValue === 'true'
  }

  const setUseNewDashboard = (value) => {
    // Set the new cookie
    Cookies.set('useNewDashboard', value.toString(), {
      domain: cookieDomain,
      expires: 365
    })
  }

  const getRedirectUrl = () => {
    const currentPath = window.location.pathname
    
    // Construct the base URL
    const protocol = window.location.protocol
    const baseDomain = window.location.host.split('.').slice(1).join('.')
    const targetHost = `next-${window.location.host}`

    // Get the mapped path or use the current path
    let targetPath = routeMappings[currentPath] || currentPath
    
    // Get current query parameters
    const searchParams = new URLSearchParams(window.location.search)
    
    // Add account and restaurant IDs if they're not already present
    const accountId = store.getters.currentAccountId
    const restaurantId = store.getters.currentRestaurantId
    
    if (accountId && !searchParams.has('accountId')) {
      searchParams.append('accountId', accountId)
    }
    if (restaurantId && !searchParams.has('restaurantId')) {
      searchParams.append('restaurantId', restaurantId)
    }
    
    // Construct the final URL with query parameters and hash
    const queryString = searchParams.toString() ? `?${searchParams.toString()}` : ''
    const hash = window.location.hash
    
    return `${protocol}//${targetHost}${targetPath}${queryString}${hash}`
  }

  const redirectToNewDashboard = () => {
    window.location.href = getRedirectUrl()
  }

  const shouldRedirectToNewDashboard = () => {
    return getUseNewDashboard()
  }

  return {
    getUseNewDashboard,
    setUseNewDashboard,
    redirectToNewDashboard,
    shouldRedirectToNewDashboard,
  }
} 